
import Vue from "vue";

export default Vue.extend({
  name: "select-agency-code-ezlynx",
  props: {
    agencyCodeOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      userAgencyCodeOptions: this.agencyCodeOptions,
      selectedAgencyCode: "",
      loading: false
    };
  },
  methods: {
    optionSelected(): void {
      this.$emit("agencyCodeSelected", this.selectedAgencyCode);
    },
    setAgencyCode($event: any) {
      this.selectedAgencyCode = $event;
    }
  }
});
